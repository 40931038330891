import i18n from "@/i18n";

/**
 * Returns an object that contains 2 lists.
 *
 * The first one returns all applicable "count" units (string values, as the backend accepts them).
 * It loops through all products associated to the Production Unit and fetch units of each unit chain (if none, it uses the Production Unit's base unit).
 * It returns all distinct units.
 *
 * The second returns all applicable weight/length/volume units (string values, as the backend accepts them).
 * It loops through all products associated to the Production Unit and fetch their target's unit (if defined).
 * - If one product defines a weight target, the list will contain all weight units (kg, g, mt, lb, oz).
 * - If one product defines a length target, the list will contain all length units (m, ft).
 * - If one product defines a volume target, the list will contain all volume units (L, mL).
 * It returns all distinct units.
 *
 * Example:
 *    {
 *      "applicableCountUnits": ["bottle", "case", "pallet"],
 *      "applicableNonCountUnits": ["kg", "g", "mt", "lb", "oz"]
 *    }
 */
export function getApplicableUnits(products, productionUnit) {
  let distinctCountUnits = new Set();
  let distinctNonCountUnits = new Set();
  products.forEach((product) => {
    const countUnits = getApplicableCountUnits(product, productionUnit);
    if (countUnits) {
      countUnits.forEach((unit) => distinctCountUnits.add(unit));
    }
    const nonCountUnits = getApplicableNonCountUnits(product);
    if (nonCountUnits) {
      nonCountUnits.forEach((unit) => distinctNonCountUnits.add(unit));
    }
  });
  return {
    applicableCountUnits: [...distinctCountUnits],
    applicableNonCountUnits: [...distinctNonCountUnits],
  };
}

export function getApplicableCountUnits(product, productionUnit) {
  let applicableUnitSet = new Set();
  applicableUnitSet.add(productionUnit.converted_unit_name);
  if (product && product.unit_conversions && product.unit_conversions.units.length > 0) {
    product.unit_conversions.units.map((unit) => {
      applicableUnitSet.add(unit.from_unit);
      applicableUnitSet.add(unit.to_unit);
    });
  }
  return [...applicableUnitSet];
}

export function getApplicableNonCountUnits(product) {
  if (product && product.target) {
    switch (product.target.unit) {
      case "kg":
      case "g":
      case "mt":
      case "lb":
      case "oz":
        return ["kg", "g", "mt", "lb", "oz"];
      case "L":
      case "mL":
        return ["L", "mL"];
      case "m":
      case "ft":
        return ["m", "ft"];
      default:
        return [];
    }
  } else {
    return [];
  }
}

export function getUnitLabelAndValue(unit) {
  switch (unit) {
    case "unit":
      return { text: i18n.t("common.units.unit"), value: "unit" };
    case "case":
      return { text: i18n.t("common.units.case"), value: "case" };
    case "pack":
      return { text: i18n.t("common.units.pack"), value: "pack" };
    case "bottle":
      return { text: i18n.t("common.units.bottle"), value: "bottle" };
    case "box":
      return { text: i18n.t("common.units.box"), value: "box" };
    case "bag":
      return { text: i18n.t("common.units.bag"), value: "bag" };
    case "pallet":
      return { text: i18n.t("common.units.pallet"), value: "pallet" };
    case "tray":
      return { text: i18n.t("common.units.tray"), value: "tray" };
    case "can":
      return { text: i18n.t("common.units.can"), value: "can" };
    case "panel":
      return { text: i18n.t("common.units.panel"), value: "panel" };
    case "cycle":
      return { text: i18n.t("common.units.cycle"), value: "cycle" };
    case "kg":
      return { text: i18n.t("common.units.kilogram"), value: "kg" };
    case "g":
      return { text: i18n.t("common.units.gram"), value: "g" };
    case "mt":
      return { text: i18n.t("common.units.ton"), value: "mt" };
    case "lb":
      return { text: i18n.t("common.units.pound"), value: "lb" };
    case "oz":
      return { text: i18n.t("common.units.ounce"), value: "oz" };
    case "L":
      return { text: i18n.t("common.units.liter"), value: "L" };
    case "mL":
      return { text: i18n.t("common.units.milliliter"), value: "mL" };
    case "m":
      return { text: i18n.t("common.units.meter"), value: "m" };
    case "ft":
      return { text: i18n.t("common.units.feet"), value: "ft" };
    default:
      return { text: i18n.t("common.units.unit"), value: "unit" };
  }
}
