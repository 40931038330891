import i18n from "@/i18n";
import { dash } from "@/helpers";

export const COUNT_UNIT = "product_unit";
export const WEIGHT_UNIT = "weight_unit";
export const LENGTH_UNIT = "length_unit";
export const VOLUME_UNIT = "volume_unit";

export const unitMap = Object.freeze({
  // count
  "count": { id: "count", name: i18n.t("common.unitNames.unit"), type: COUNT_UNIT },
  "unit": { id: "unit", name: i18n.t("common.unitNames.unit"), type: COUNT_UNIT },
  "case": { id: "case", name: i18n.t("common.unitNames.case"), type: COUNT_UNIT },
  "pack": { id: "pack", name: i18n.t("common.unitNames.pack"), type: COUNT_UNIT },
  "bottle": { id: "bottle", name: i18n.t("common.unitNames.bottle"), type: COUNT_UNIT },
  "box": { id: "box", name: i18n.t("common.unitNames.box"), type: COUNT_UNIT },
  "cycle": { id: "cycle", name: i18n.t("common.unitNames.cycle"), type: COUNT_UNIT },
  "bag": { id: "bag", name: i18n.t("common.unitNames.bag"), type: COUNT_UNIT },
  "pallet": { id: "pallet", name: i18n.t("common.unitNames.pallet"), type: COUNT_UNIT },
  "can": { id: "can", name: i18n.t("common.unitNames.can"), type: COUNT_UNIT },
  // weight
  "kg": { id: "kg", name: i18n.t("tiles.quantity.totalWeight.units.kilograms"), type: WEIGHT_UNIT, conversionToStandard: 1.0 },
  "g": { id: "g", name: i18n.t("tiles.quantity.totalWeight.units.grams"), type: WEIGHT_UNIT, conversionToStandard: 0.001 },
  "mt": { id: "mt", name: i18n.t("tiles.quantity.totalWeight.units.tons"), type: WEIGHT_UNIT, conversionToStandard: 1000 },
  "lb": { id: "lb", name: i18n.t("tiles.quantity.totalWeight.units.pounds"), type: WEIGHT_UNIT, conversionToStandard: 0.453592 },
  "oz": { id: "oz", name: i18n.t("tiles.quantity.totalWeight.units.ounces"), type: WEIGHT_UNIT, conversionToStandard: 0.0283495 },
  "t": { id: "t", name: i18n.t("tiles.quantity.totalWeight.units.imperialTons"), type: WEIGHT_UNIT, conversionToStandard: 1016.05 },
  "kilogram": { id: "kg", name: i18n.t("tiles.quantity.totalWeight.units.kilograms"), type: WEIGHT_UNIT, conversionToStandard: 1.0 },
  "ton": { id: "mt", name: i18n.t("tiles.quantity.totalWeight.units.tons"), type: WEIGHT_UNIT, conversionToStandard: 1000 },
  "pound": { id: "lb", name: i18n.t("tiles.quantity.totalWeight.units.pounds"), type: WEIGHT_UNIT, conversionToStandard: 0.453592 },
  "ounce": { id: "oz", name: i18n.t("tiles.quantity.totalWeight.units.ounces"), type: WEIGHT_UNIT, conversionToStandard: 0.0283495 },
  // volume
  "l": { id: "l", name: i18n.t("tiles.quantity.totalVolume.units.liters"), type: VOLUME_UNIT, conversionToStandard: 1.0 },
  "ml": { id: "ml", name: i18n.t("tiles.quantity.totalVolume.units.milliliters"), type: VOLUME_UNIT, conversionToStandard: 0.001 },
  "hl": { id: "hl", name: i18n.t("tiles.quantity.totalVolume.units.hectoliters"), type: VOLUME_UNIT, conversionToStandard: 100.0 },
  "fl-oz": { id: "oz", name: i18n.t("tiles.quantity.totalVolume.units.fluidOunces"), type: VOLUME_UNIT, conversionToStandard: 0.0295735 },
  "gal": { id: "gal", name: i18n.t("tiles.quantity.totalVolume.units.gallons"), type: VOLUME_UNIT, conversionToStandard: 3.78541 },
  "gal-uk": { id: "uk", name: i18n.t("tiles.quantity.totalVolume.units.imperialGallons"), type: VOLUME_UNIT, conversionToStandard: 4.54609 },
  "liter": { id: "l", name: i18n.t("tiles.quantity.totalVolume.units.liters"), type: VOLUME_UNIT, conversionToStandard: 1.0 },
  // length
  "m": { id: "m", name: i18n.t("tiles.quantity.totalLength.units.meters"), type: LENGTH_UNIT, conversionToStandard: 1.0 },
  "mm": { id: "mm", name: i18n.t("tiles.quantity.totalLength.units.millimeters"), type: LENGTH_UNIT, conversionToStandard: 0.001 },
  "km": { id: "km", name: i18n.t("tiles.quantity.totalLength.units.kilometers"), type: LENGTH_UNIT, conversionToStandard: 1000.0 },
  "in": { id: "in", name: i18n.t("tiles.quantity.totalLength.units.inches"), type: LENGTH_UNIT, conversionToStandard: 0.0254 },
  "ft": { id: "ft", name: i18n.t("tiles.quantity.totalLength.units.feet"), type: LENGTH_UNIT, conversionToStandard: 0.3048 },
  "mi": { id: "mi", name: i18n.t("tiles.quantity.totalLength.units.miles"), type: LENGTH_UNIT, conversionToStandard: 1609.34 },
  "yd": { id: "yd", name: i18n.t("tiles.quantity.totalLength.units.yards"), type: LENGTH_UNIT, conversionToStandard: 0.9144 },
  "meter": { id: "m", name: i18n.t("tiles.quantity.totalLength.units.meters"), type: LENGTH_UNIT, conversionToStandard: 1.0 },
  "feet": { id: "ft", name: i18n.t("tiles.quantity.totalLength.units.feet"), type: LENGTH_UNIT, conversionToStandard: 0.3048 },
});

export const convertFromTo = (fromUnit, toUnit, value) => {
  const fromUnitDetails = unitMap[fromUnit];
  const toUnitDetails = unitMap[toUnit];
  if (!fromUnitDetails || !toUnitDetails) return null; // can't convert if one unit is not supported
  if (fromUnitDetails.type === COUNT_UNIT || toUnitDetails.type === COUNT_UNIT) return null; // can't convert count units
  if (fromUnitDetails.type !== toUnitDetails.type) return null; // can't convert if not the same unit type
  // 1. convert value in fromUnit to standard unit
  // 2. convert value in standard unit to toUnit
  return (value * fromUnitDetails.conversionToStandard) / toUnitDetails.conversionToStandard;
};

export const convertKilogramTo = (value, unit) => {
  return convertFromTo("kg", unit, value);
};

export const convertMeterTo = (value, unit) => {
  return convertFromTo("m", unit, value);
};

export const convertLiterTo = (value, unit) => {
  return convertFromTo("l", unit, value);
};

export const getUnitLabel = (unit) => {
  return unitMap[unit]?.name ?? dash;
};
