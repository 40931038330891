import http from "@/BaseServiceHttp";

class DeviceService {
  getDevices(factoryId) {
    return http.get(`/factories/${factoryId}/devices`);
  }
  getWiFiSignalStrength(factoryId, startDate, endDate) {
    return http.get(`/factories/${factoryId}/devices/wifi-signal-strength?startDate=${startDate}&endDate=${endDate}`);
  }
  isManualRejectEntryDataSource(dataSource) {
    if (dataSource) {
      return dataSource.id === "reject-01"; // Data source ID `reject-01` means `Manual Reject Entry form`.
    }
    return false;
  }
}

export default new DeviceService();
