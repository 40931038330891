import http from "../../BaseServiceHttp";

class RejectService {
  submitReject(deviceId, rejectPayload) {
    return http.post(`/measures`, rejectPayload, {
      headers: {
        "X-DeviceId": deviceId,
      },
    });
  }
}

export default new RejectService();
